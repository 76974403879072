import $ from 'jquery';

// ---------- Carto ----------

if($('#carto').length) {

	var map = L.map('carto', {
		zoomControl: false
	}).setView([47.2382, -1.5607], 9);

	L.control.zoom({
		position:'topright'
	}).addTo(map);

	L.tileLayer('https://api.mapbox.com/styles/v1/tchercheuse/cj85wrdld23c12rk2lp7n9qv3/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1IjoidGNoZXJjaGV1c2UiLCJhIjoiY2ozZXdscm5iMDAwMDMyczMxYmM1bTA1YyJ9.v_wmQ9N9_jqc1WttgC4qsA', {
		attribution: 'Map data &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors, <a href="http://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="http://mapbox.com">Mapbox</a>',
		maxZoom:     12,
		//	id:          'mapbox.satellite',
		//	accessToken: 'pk.eyJ1IjoidGNoZXJjaGV1c2UiLCJhIjoiY2ozZXdscm5iMDAwMDMyczMxYmM1bTA1YyJ9.v_wmQ9N9_jqc1WttgC4qsA'
	}).addTo(map);

	var geojson_layer = '';

	var geojson_filename = false;
	$('.appellation a').on('click', function(e){
		e.preventDefault();

		var appellation = $(this);
		//var appellation_parent_color = appellation.closest('.panel-default').data('color');



		var appellation_color = '#0080c4';

		if(geojson_filename = $(this).data('geojson')) {

			if(geojson_layer) {
				//console.log('remove');
				map.closePopup();
				map.removeLayer(geojson_layer);
			}

			geojson_layer = new L.geoJson(false, {
				style:         {
					fillColor:  appellation_color,
					weight:      2,
					opacity:     1,
					color:       appellation_color,  //Outline color
					fillOpacity: 0.3,
					dashArray: '20',
				},
				onEachFeature: function(feature, layer) {

					if (!appellation.hasClass('dropdown-toggle')) {

						var popup_content = get_popup_content(appellation);

						if(popup_content) {
							var popup = L.popup()
								.setLatLng(layer.getBounds().getCenter())
								.setContent(popup_content)
								.openOn(map);
						}
					}

					layer.on({
						mouseover: function(e) {
							//console.log(e.target);
							e.target.setStyle({
								weight:      6,
								fillOpacity: 0.7,
								dashArray: '',
							});
						},
						mouseout:  function(e) {
							e.target.setStyle({
								weight:      4,
								fillOpacity: 0.3,
								dashArray: '20',
							});
						},
						click:     function(e) {
							if(e.target.feature.properties.id) {
								self = $('.search').find('[data-title="' + e.target.feature.properties.id + '"]');
								//console.log(self);

								if (!appellation.hasClass('dropdown-toggle')) {
									var popup_content = get_popup_content(appellation);

								} else {
									var popup_content = get_popup_content(self);

								}

								var popup = L.popup()
									.setLatLng(layer.getBounds().getCenter())
									.setContent(popup_content)
									.openOn(map);
								popup.openOn(map);
							}
							map.fitBounds(e.target.getBounds());

						},
					});
				}
			});

			geojson_layer.addTo(map);
			//console.log(geojson_filename);
			$.getJSON('/geojson/'+geojson_filename, function(data) {
				geojson_layer.addData(data);
				map.fitBounds(geojson_layer.getBounds());
			});

			$(appellation).next().find('a').off('click').click(function(e) {
				e.preventDefault();
				e.stopPropagation();
				var appellation = $(this).data('title');
				var match = geojson_layer.eachLayer(function(layer) {
					if (layer.feature.properties.id == appellation) {
						if(layer.feature.properties.id) {
							var self = $('.search').find('[data-title="' + layer.feature.properties.id + '"]');
							//console.log(self);

							layer.setStyle({
								weight:      6,
								fillOpacity: 0.7,
								dashArray: '',
							});

							var popup_content = get_popup_content(self);

							var popup = L.popup()
								.setLatLng(layer.getBounds().getCenter())
								.setContent(popup_content)
								.openOn(map);
							popup.openOn(map);
						}
						map.fitBounds(layer.getBounds());
					}
				})
				$(this).closest('.dropdown-menu').removeClass('show');
			})
		}

		return false;
	});
}

function onEachFeature(feature, layer) {
	layer.on({
		mouseover: highlightFeature,
		mouseout:  resetHighlight,
		click:     zoomToFeature,

	});
	layer.bindPopup();
}

// HIGHLIGHT FEATURE = MOUSEOVER
function highlightFeature(e) {
	var layer = e.target;

	layer.setStyle({
		weight:      5,
		color:       '#666',
		dashArray:   '',
		fillOpacity: 0.7
	});
};

// HIGHLIGHT FEATURE = MOUSE LEFT
function resetHighlight(e) {
	L.geoJSON().resetStyle(e.target);
};

// ZOOM TO THE REGION
function zoomToFeature(e) {
	//map.fitBounds(e.target.getBounds());
}

function is_touch_device() {
	return 'ontouchstart' in window        // works on most browsers
		|| navigator.maxTouchPoints;       // works on IE10/11 and Surface
};

function get_popup_content(el) {
	var self = el;
	var popup_content = false;

	var appellation_image_url = jQuery(self).data('image');
	var appellation_page_url = jQuery(self).data('url');
	var appellation_title_image = jQuery(self).data('title_image');
	var appellation_title = jQuery(self).data('title');

	var popup_content = '<a href="' + appellation_page_url + '" target="_blank"><div class="carto-image" style="background-image:url(' + appellation_image_url + ')"></div>';

	if(typeof appellation_title_image != 'undefined') {
		popup_content = popup_content + '<div class=\"carto-title\"><img src="' + appellation_title_image + '"></div></div>';
	} else {

		popup_content = popup_content + '<div class=\"carto-title\">' + appellation_title + '</div></div>';
	}

	popup_content = popup_content + '</a>';


	return popup_content;
}
