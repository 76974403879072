import $ from 'jquery';
import Swiper from 'swiper';
import { Autoplay, EffectFade, Navigation, Pagination } from 'swiper/modules';

import { $Elements, Durations, Events } from './constants';

$Elements.document.on(Events.page.enter, () => {

	// ---------- Carousel Sea Wine Pairing ----------

	$('.sea-wine-pairing-section .section-carousel').each(function() {

		if(!this.swiper) {

			const swiper = new Swiper(this, {
				speed: Durations.animations.ms,
				loop: true,
				allowTouchMove: true,
				slidesPerView: "auto",
				centeredSlides: false,
				autoplay: {
					disableOnInteraction: false,
				},
				modules: [Autoplay],
			});
		}
	});

	// ---------- Carousel Section Articles  ----------

	$('.section-articles .section-carousel').each(function() {

		if(!this.swiper) {

			const swiper = new Swiper(this, {
				speed: Durations.animations.ms,
				allowTouchMove: true,
				slidesPerView: "auto",
				centeredSlides: false,
				modules: [Navigation],
				navigation: {
					nextEl: this.querySelector('.swiper-button-next'),
					prevEl: this.querySelector('.swiper-button-prev'),
				},
				breakpoints: {
					1440: {
						slidesPerView: 3,
						initialSlide: 1
					},
					1024: {
						slidesPerView: 4,
						initialSlide: 1
					},
					768: {
						slidesPerView: "auto",
						initialSlide: 1
					},
					480: {
						slidesPerView: "auto",
						initialSlide: 1
					},
				},
			});
		}
	});

	// ---------- Carousel Footer----------

	$('.carousel-footer-section .section-carousel').each(function() {

		if(!this.swiper) {

			const swiper = new Swiper(this, {
				speed: Durations.animations.ms,
				loop: false,
				slidesPerView: "auto",
				allowTouchMove: true,
				centeredSlides: false,
				modules: [Autoplay, Navigation],
				autoplay: {
					disableOnInteraction: false,
				},
				navigation: {
					nextEl: this.querySelector('.swiper-button-next'),
					prevEl: this.querySelector('.swiper-button-prev'),
				},
			});
		}
	});

	// ---------- Image Carousel + Partners Carousel ----------

	$('.content-section .section-slideshow, .content-section .column-slideshow').each(function() {
//	$('.content-section .section-slideshow, .content-section .column-slideshow, .partners-carousel-section .section-carousel').each(function() {

		if(!this.swiper) {

			const swiper = new Swiper(this, {
				speed: Durations.animations.ms,
				allowTouchMove: true,
				//spaceBetween: 34,
				slidesPerView: "auto",

				modules: [Autoplay, Navigation],
				autoplay: {
					disableOnInteraction: false,
					delay: 3000,
				},
				navigation: {
					nextEl: this.querySelector('.swiper-button-next'),
					prevEl: this.querySelector('.swiper-button-prev'),
				},
			});
		}
	});

});
