import $ from 'jquery';

import { $Elements, Classes, Events, Breakpoints, Elements } from './constants';
import { disableBodyScroll, enableBodyScroll, smoothScrollTo } from './helpers';
export const calculateMenuOffset = (target) => target.offset().top - $Elements.HeaderWrapper.height();

$Elements.document.on(Events.page.enter, () => {

	// ---------- Menu ----------

	$Elements.PageWrapper.on('click', '.menu-toggle, a[href="#menu-wrapper"], .menu-overlay', function(e) {
		e.preventDefault();

		if($Elements.body.hasClass(Classes.menuOpen)) {
			$Elements.body.removeClass(Classes.menuOpen);
			enableBodyScroll();
		}
		else {
			$Elements.body.addClass(Classes.menuOpen);
			disableBodyScroll();
		}
	});

	$Elements.PageWrapper.on('click', '.navbar-menu a, .menu-wrapper a', function(e) {

		const linkTarget = this.getAttribute('href');

		const sectionTarget = linkTarget.substring(linkTarget.lastIndexOf('#'), linkTarget.length);

		if(sectionTarget.substring(0, 1) === '#') {

			e.preventDefault();

			const section = $(sectionTarget);

			if(section.length) {

				window.history.replaceState(null, null, sectionTarget);

				$Elements.body.removeClass(Classes.menuOpen);
				enableBodyScroll()
				smoothScrollTo(section);


			}
			else if(sectionTarget !== '#') {
				window.location = linkTarget;
			}
		}
	});
});
// ---------- Menu ----------

const menu_wrapper = $('.menu-wrapper');

$('.mobile-menu-overlay').click(function(e) {
	e.preventDefault();
	$('body').removeClass('mobile-menu-open');
	menu_wrapper.find('.main-menu').removeClass('submenu-0 submenu-1 submenu-2')
	menu_wrapper.find('li').removeClass('submenu-open');
});

$('.mobile-menu-toggle').click(function(e) {
	e.preventDefault();
	$('body').toggleClass('mobile-menu-open');
	menu_wrapper.find('.main-menu').removeClass('submenu-0 submenu-1 submenu-2')
	menu_wrapper.find('li').removeClass('submenu-open');
});

menu_wrapper.find('button[data-open-submenu]').each(function() {
	var self = $(this);
	var depth = self.data('open-submenu');

	self.click(function(e) {
		e.preventDefault();
		menu_wrapper.find('.main-menu').removeClass('submenu-0 submenu-1 submenu-2').addClass('submenu-' + depth);
		self.parent().parent().find('> li').removeClass('submenu-open');
		self.parent().addClass('submenu-open');
		$('body').addClass('sub-menu');

	});
});

menu_wrapper.find('button[data-close-submenu]').each(function() {
	var self = $(this);
	var depth = self.data('close-submenu');

	self.click(function(e) {
		e.preventDefault();
		menu_wrapper.find('.main-menu').removeClass('submenu-0 submenu-1 submenu-2').addClass('submenu-' + depth);
		menu_wrapper.find('li').removeClass('submenu-open');
		$('body').removeClass('sub-menu');
		//self.parent().addClass('submenu-open');
	});
});



$Elements.document.on(Events.page.loaded, () => {

	const linkTarget = window.location.href;
	const sectionTarget = linkTarget.substring(linkTarget.lastIndexOf('#'), linkTarget.length);

	if(sectionTarget.substring(0, 1) === '#') {

		const section = $(sectionTarget);

		if(section.length) {
			$Elements.body.removeClass(Classes.menuOpen);
			enableBodyScroll($Elements.MenuWrapper);
			smoothScrollTo(section, 0);
		}
	}
});

