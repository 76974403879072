import $ from 'jquery';
import { gsap } from 'gsap';

import { $Elements, Animations, Classes, Events } from '../global/constants';

// ---------- Init Scroll Animation ----------

export const initScrollAnimations = () => {

	// ---------- Menu ----------

	$Elements.HeaderWrapper.each(function() {

		Animations.global.push(() => {

			gsap.timeline({
				scrollTrigger: {
					start: 1,
					end: 'max',
					onUpdate: (self) => {
						(self.direction === -1) ? this.classList.remove(Classes.shrink) : this.classList.add(Classes.shrink);
						(self.progress > 0) ? this.classList.add(Classes.shrinkable) : this.classList.remove(Classes.shrinkable);
					}
				}
			});
		});
	});

	// ---------- Animated Section ----------

	$('.animated-section:not(.transition-clone), .section-infographie .animated-item').each(function() {

		Animations.global.push(() => {

			const timeline = gsap.timeline({
				scrollTrigger: {
					trigger: this,
					once: true,
					start: 'top 80%',
					toggleClass: Classes.active,
				}
			});

			gsap.timeline({
				scrollTrigger: {
					start: -1,
					end: 10,
					toggleClass: {
						targets: document.body,
						className: 'on-top'
					},
				}
			});
		});
	});

	// ---------- Animated SVG Section ----------

	$('.section-infographie > svg > g').each(function() {

		this.classList.add('animated-item');

		Animations.global.push(() => {

			const timeline = gsap.timeline({
				scrollTrigger: {
					trigger: this,
					once: true,
					start: 'top 80%',
					toggleClass: Classes.active,
				}
			});

			gsap.timeline({
				scrollTrigger: {
					start: -1,
					end: 10,
					toggleClass: {
						targets: document.body,
						className: 'on-top'
					},
				}
			});
		});
	});

	// ---------- Header ----------

		$Elements.HeaderWrapper.each(function() {

				Animations.mobile.min.push(() => {

				gsap.timeline({
					scrollTrigger: {
						start: 0,
						end: 'max',
						onUpdate: (self) => {
							self.direction === 1 ? this.classList.add('shrink') : this.classList.remove('shrink');
						}
					}
				});


				gsap.timeline({
					scrollTrigger: {
						start: () => $Elements.body.hasClass('page-template-template-adherents') ? $('.content-section:nth-child(3)').height() : $('.content-section:first-child').height(),
						onEnter: () => $Elements.body.addClass('menu-light'),
						onLeaveBack: () => $Elements.body.removeClass('menu-light')
					}
				});

				gsap.timeline({
					scrollTrigger: {
						start: 0,
						end: () => $Elements.body.hasClass('page-template-template-adherents') ? $('.content-section:nth-child(3)').height() + ($(this).height() * 2) : $('.content-section:first-child').height() + ($(this).height() * 2),
						toggleClass: {
							targets: this,
							className: 'force-shrink'
						}
					}
				});
			});

			Animations.mobile.max.push(() => {

				gsap.timeline({
					scrollTrigger: {
						start: 1,
						onEnter: () => $Elements.body.addClass('menu-light'),
						onLeaveBack: () => $Elements.body.removeClass('menu-light')
					}
				});
			});
		});
	// ---------- Content Sections ----------

	$('.parallax-section:not(.fixed):not(.transition-clone)').each(function() {

		Animations.mobile.max.push(() => this.classList.remove(Classes.moving));

		Animations.mobile.min.push(() => {

			this.classList.add(Classes.moving);

			const image = this.querySelector(':scope .section-image ,:scope .column-image');
			const speed = parseInt(this.dataset.speed, 10);

			if(image) {

				const timeline = gsap.timeline({
					scrollTrigger: {
						trigger: this,
						start: 'top bottom',
						end: 'bottom top',
						scrub: true,
						invalidateOnRefresh: true,
					},
				});

				const offset = () => image.offsetHeight - ((image.offsetHeight - this.offsetHeight) / 2);

				timeline.fromTo(
					image,
					{ y: () => offset() * (speed / 100), ease: 'none' },
					{ y: () => offset() * (-speed / 100), ease: 'none' }
				);
			}
		});
	});
};

$Elements.document.on(Events.page.enter, () => initScrollAnimations());
